import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _299fad18 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _146858dc = () => interopDefault(import('../pages/checkout/order/created.vue' /* webpackChunkName: "pages/checkout/order/created" */))
const _609b1539 = () => interopDefault(import('../pages/products/bestsellers.vue' /* webpackChunkName: "pages/products/bestsellers" */))
const _0309e01c = () => interopDefault(import('../pages/cms/blog.vue' /* webpackChunkName: "pages/cms/blog" */))
const _0381530e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0b01c686 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _0e8e71b9 = () => interopDefault(import('../pages/auth/login/index.vue' /* webpackChunkName: "pages/auth/login/index" */))
const _a78675b0 = () => interopDefault(import('../pages/auth/login/seller.vue' /* webpackChunkName: "pages/auth/login/seller" */))
const _3ccf74b0 = () => interopDefault(import('../pages/products/novelties.vue' /* webpackChunkName: "pages/products/novelties" */))
const _7f6c2212 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _831df0aa = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "pages/product" */))
const _1ae5e1ee = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _4e07935b = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _09514b69 = () => interopDefault(import('../pages/auth/register/index.vue' /* webpackChunkName: "pages/auth/register/index" */))
const _09d52178 = () => interopDefault(import('../pages/auth/register/seller.vue' /* webpackChunkName: "pages/auth/register/seller" */))
const _4affb764 = () => interopDefault(import('../pages/products/sale.vue' /* webpackChunkName: "pages/products/sale" */))
const _6ef1112e = () => interopDefault(import('../pages/print.vue' /* webpackChunkName: "pages/print" */))
const _4b48794e = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _327cb440 = () => interopDefault(import('../pages/surcharge.vue' /* webpackChunkName: "pages/surcharge" */))
const _b59a6050 = () => interopDefault(import('../pages/admin/bank-transfer.vue' /* webpackChunkName: "pages/admin/bank-transfer" */))
const _1bee81ee = () => interopDefault(import('../pages/admin/coupons.vue' /* webpackChunkName: "pages/admin/coupons" */))
const _568ec529 = () => interopDefault(import('../pages/admin/invoice.vue' /* webpackChunkName: "pages/admin/invoice" */))
const _638d7cee = () => interopDefault(import('../pages/profile/change-password.vue' /* webpackChunkName: "pages/profile/change-password" */))
const _2df44242 = () => interopDefault(import('../pages/profile/orders/index.vue' /* webpackChunkName: "pages/profile/orders/index" */))
const _40929a0e = () => interopDefault(import('../pages/profile/comments.vue' /* webpackChunkName: "pages/profile/comments" */))
const _5aaa4024 = () => interopDefault(import('../pages/profile/edit.vue' /* webpackChunkName: "pages/profile/edit" */))
const _7deaa25a = () => interopDefault(import('../pages/profile/tradeCredit.vue' /* webpackChunkName: "pages/profile/tradeCredit" */))
const _3ad6eacf = () => interopDefault(import('../pages/messenger.vue' /* webpackChunkName: "pages/messenger" */))
const _00ea236e = () => interopDefault(import('../pages/auth/confirm-phone.vue' /* webpackChunkName: "pages/auth/confirm-phone" */))
const _19aae320 = () => interopDefault(import('../pages/admin/sizes/add.vue' /* webpackChunkName: "pages/admin/sizes/add" */))
const _0a358d06 = () => interopDefault(import('../pages/admin/product/add.vue' /* webpackChunkName: "pages/admin/product/add" */))
const _75c94f33 = () => interopDefault(import('../pages/admin/addonGroups/index.vue' /* webpackChunkName: "pages/admin/addonGroups/index" */))
const _41354094 = () => interopDefault(import('../pages/admin/change-password.vue' /* webpackChunkName: "pages/admin/change-password" */))
const _126772ea = () => interopDefault(import('../pages/admin/offer/add.vue' /* webpackChunkName: "pages/admin/offer/add" */))
const _51f52bb6 = () => interopDefault(import('../pages/admin/offer/list.vue' /* webpackChunkName: "pages/admin/offer/list" */))
const _c7fe6f68 = () => interopDefault(import('../pages/admin/descriptionTemplates/list.vue' /* webpackChunkName: "pages/admin/descriptionTemplates/list" */))
const _05a4df48 = () => interopDefault(import('../pages/admin/orders/index.vue' /* webpackChunkName: "pages/admin/orders/index" */))
const _45c87514 = () => interopDefault(import('../pages/admin/clientGroups/index.vue' /* webpackChunkName: "pages/admin/clientGroups/index" */))
const _516eb3e2 = () => interopDefault(import('../pages/admin/profile-edit.vue' /* webpackChunkName: "pages/admin/profile-edit" */))
const _55f00ef7 = () => interopDefault(import('../pages/admin/filters.vue' /* webpackChunkName: "pages/admin/filters" */))
const _8f9ffea2 = () => interopDefault(import('../pages/admin/sizes/list.vue' /* webpackChunkName: "pages/admin/sizes/list" */))
const _3c63e56e = () => interopDefault(import('../pages/admin/coupons&groupDeal.vue' /* webpackChunkName: "pages/admin/coupons&groupDeal" */))
const _ae4731b8 = () => interopDefault(import('../pages/admin/tradeCredit/list.vue' /* webpackChunkName: "pages/admin/tradeCredit/list" */))
const _65203770 = () => interopDefault(import('../pages/admin/spreadsheet.vue' /* webpackChunkName: "pages/admin/spreadsheet" */))
const _69e22e0e = () => interopDefault(import('../pages/admin/clients/list.vue' /* webpackChunkName: "pages/admin/clients/list" */))
const _3696a0ca = () => interopDefault(import('../pages/admin/marketing/list.vue' /* webpackChunkName: "pages/admin/marketing/list" */))
const _9aa5b5c8 = () => interopDefault(import('../pages/admin/employees/list.vue' /* webpackChunkName: "pages/admin/employees/list" */))
const _6220f10f = () => interopDefault(import('../pages/admin/messenger.vue' /* webpackChunkName: "pages/admin/messenger" */))
const _3def0649 = () => interopDefault(import('../pages/admin/cms/page.vue' /* webpackChunkName: "pages/admin/cms/page" */))
const _37d3cbda = () => interopDefault(import('../pages/admin/cms/post.vue' /* webpackChunkName: "pages/admin/cms/post" */))
const _3d9892a8 = () => interopDefault(import('../pages/admin/pricesOnQuantity.vue' /* webpackChunkName: "pages/admin/pricesOnQuantity" */))
const _037f141c = () => interopDefault(import('../pages/admin/product/list.vue' /* webpackChunkName: "pages/admin/product/list" */))
const _33df8ea8 = () => interopDefault(import('../pages/admin/store/edit.vue' /* webpackChunkName: "pages/admin/store/edit" */))
const _13e248b2 = () => interopDefault(import('../pages/admin/cms/updatePage.vue' /* webpackChunkName: "pages/admin/cms/updatePage" */))
const _0dc70e43 = () => interopDefault(import('../pages/admin/cms/updatePost.vue' /* webpackChunkName: "pages/admin/cms/updatePost" */))
const _c358d03c = () => interopDefault(import('../pages/admin/shipping.vue' /* webpackChunkName: "pages/admin/shipping" */))
const _63aa6dd7 = () => interopDefault(import('../pages/admin/tools.vue' /* webpackChunkName: "pages/admin/tools" */))
const _2e1f9f82 = () => interopDefault(import('../pages/admin/payment.vue' /* webpackChunkName: "pages/admin/payment" */))
const _a3b8fc58 = () => interopDefault(import('../pages/admin/addonGroups/manage.vue' /* webpackChunkName: "pages/admin/addonGroups/manage" */))
const _210433e3 = () => interopDefault(import('../pages/admin/descriptionTemplates/add.vue' /* webpackChunkName: "pages/admin/descriptionTemplates/add" */))
const _311fd3f1 = () => interopDefault(import('../pages/admin/clientGroups/manage.vue' /* webpackChunkName: "pages/admin/clientGroups/manage" */))
const _007491ce = () => interopDefault(import('../pages/admin/groupDeal/add.vue' /* webpackChunkName: "pages/admin/groupDeal/add" */))
const _2b9ea6b4 = () => interopDefault(import('../pages/admin/marketing/add.vue' /* webpackChunkName: "pages/admin/marketing/add" */))
const _301e05da = () => interopDefault(import('../pages/admin/employees/add.vue' /* webpackChunkName: "pages/admin/employees/add" */))
const _a96e2090 = () => interopDefault(import('../pages/admin/descriptionTemplates/edit.vue' /* webpackChunkName: "pages/admin/descriptionTemplates/edit" */))
const _180651f2 = () => interopDefault(import('../pages/admin/marketing/edit.vue' /* webpackChunkName: "pages/admin/marketing/edit" */))
const _86bb2ac8 = () => interopDefault(import('../pages/auth/confirm-email.vue' /* webpackChunkName: "pages/auth/confirm-email" */))
const _6ad1ed2a = () => interopDefault(import('../pages/profile/orders/_id.vue' /* webpackChunkName: "pages/profile/orders/_id" */))
const _bbb05792 = () => interopDefault(import('../pages/admin/clients/details.vue' /* webpackChunkName: "pages/admin/clients/details" */))
const _14b254ff = () => interopDefault(import('../pages/admin/groupDeal/deals.vue' /* webpackChunkName: "pages/admin/groupDeal/deals" */))
const _7c1566f0 = () => interopDefault(import('../pages/admin/employees/edit.vue' /* webpackChunkName: "pages/admin/employees/edit" */))
const _b690597c = () => interopDefault(import('../pages/admin/groupDeal/edit.vue' /* webpackChunkName: "pages/admin/groupDeal/edit" */))
const _3364dcde = () => interopDefault(import('../pages/admin/offer/edit.vue' /* webpackChunkName: "pages/admin/offer/edit" */))
const _745bf9c4 = () => interopDefault(import('../pages/admin/orders/_id.vue' /* webpackChunkName: "pages/admin/orders/_id" */))
const _0d889d5e = () => interopDefault(import('../pages/admin/product/edit.vue' /* webpackChunkName: "pages/admin/product/edit" */))
const _710fafca = () => interopDefault(import('../pages/admin/sizes/edit.vue' /* webpackChunkName: "pages/admin/sizes/edit" */))
const _0e95222c = () => interopDefault(import('../pages/admin/tradeCredit/details.vue' /* webpackChunkName: "pages/admin/tradeCredit/details" */))
const _8c4e307c = () => interopDefault(import('../pages/products/bestsellers-category.vue' /* webpackChunkName: "pages/products/bestsellers-category" */))
const _7fd54347 = () => interopDefault(import('../pages/groupDeal.vue' /* webpackChunkName: "pages/groupDeal" */))
const _92ec779a = () => interopDefault(import('../pages/products/novelties-category.vue' /* webpackChunkName: "pages/products/novelties-category" */))
const _11c5ecdc = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _2d89ff66 = () => interopDefault(import('../pages/products/sale-category.vue' /* webpackChunkName: "pages/products/sale-category" */))
const _6c33de05 = () => interopDefault(import('../pages/products/category.vue' /* webpackChunkName: "pages/products/category" */))
const _e63e1f6e = () => interopDefault(import('../pages/cms/postPage.vue' /* webpackChunkName: "pages/cms/postPage" */))
const _44f4b066 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link',
  linkExactActiveClass: 'is-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _299fad18,
    props: false,
    name: "404___de"
  }, {
    path: "/auftragsbezogen",
    component: _146858dc,
    props: false,
    name: "checkout-order-created___de"
  }, {
    path: "/bestseller",
    component: _609b1539,
    props: false,
    name: "products-bestsellers___de"
  }, {
    path: "/blog",
    component: _0309e01c,
    props: false,
    name: "cms-blog___de"
  }, {
    path: "/en",
    component: _0381530e,
    props: false,
    name: "index___en"
  }, {
    path: "/Kasse",
    component: _0b01c686,
    props: false,
    name: "checkout___de"
  }, {
    path: "/Login",
    component: _0e8e71b9,
    props: false,
    name: "auth-login___de"
  }, {
    path: "/Login-Verkaufer",
    component: _a78675b0,
    props: false,
    name: "auth-login-seller___de"
  }, {
    path: "/novelties",
    component: _3ccf74b0,
    props: false,
    name: "products-novelties___de"
  }, {
    path: "/Passwort-vergessen",
    component: _7f6c2212,
    props: false,
    name: "auth-forgot-password___de"
  }, {
    path: "/product",
    component: _831df0aa,
    props: false,
    children: [{
      path: "/:productName-p:productId(\\d+)",
      component: _1ae5e1ee,
      name: "product___de"
    }]
  }, {
    path: "/Produkt",
    component: _4e07935b,
    props: false,
    name: "products___de"
  }, {
    path: "/Register",
    component: _09514b69,
    props: false,
    name: "auth-register___de"
  }, {
    path: "/Register-Verkaufer",
    component: _09d52178,
    props: false,
    name: "auth-register-seller___de"
  }, {
    path: "/sale",
    component: _4affb764,
    props: false,
    name: "products-sale___de"
  }, {
    path: "/uberdrucken",
    component: _6ef1112e,
    props: false,
    name: "print___de"
  }, {
    path: "/Verkaufer-Panel",
    component: _4b48794e,
    props: false,
    name: "admin___de"
  }, {
    path: "/zuzahlung",
    component: _327cb440,
    props: false,
    name: "surcharge___de"
  }, {
    path: "/admin/bank-transfer",
    component: _b59a6050,
    props: false,
    name: "admin-bank-transfer___de"
  }, {
    path: "/admin/coupons",
    component: _1bee81ee,
    props: false,
    name: "admin-coupons___de"
  }, {
    path: "/admin/invoice",
    component: _568ec529,
    props: false,
    name: "admin-invoice___de"
  }, {
    path: "/en/404",
    component: _299fad18,
    props: false,
    name: "404___en"
  }, {
    path: "/en/bestsellers",
    component: _609b1539,
    props: false,
    name: "products-bestsellers___en"
  }, {
    path: "/en/blog",
    component: _0309e01c,
    props: false,
    name: "cms-blog___en"
  }, {
    path: "/en/checkout",
    component: _0b01c686,
    props: false,
    name: "checkout___en"
  }, {
    path: "/en/forgot-password",
    component: _7f6c2212,
    props: false,
    name: "auth-forgot-password___en"
  }, {
    path: "/en/login",
    component: _0e8e71b9,
    props: false,
    name: "auth-login___en"
  }, {
    path: "/en/novelties",
    component: _3ccf74b0,
    props: false,
    name: "products-novelties___en"
  }, {
    path: "/en/order-created",
    component: _146858dc,
    props: false,
    name: "checkout-order-created___en"
  }, {
    path: "/en/print",
    component: _6ef1112e,
    props: false,
    name: "print___en"
  }, {
    path: "/en/product",
    component: _831df0aa,
    props: false,
    children: [{
      path: "/en/:productName-p:productId(\\d+)",
      component: _1ae5e1ee,
      name: "product___en"
    }]
  }, {
    path: "/en/products",
    component: _4e07935b,
    props: false,
    name: "products___en"
  }, {
    path: "/en/register",
    component: _09514b69,
    props: false,
    name: "auth-register___en"
  }, {
    path: "/en/sale",
    component: _4affb764,
    props: false,
    name: "products-sale___en"
  }, {
    path: "/en/seller-login",
    component: _a78675b0,
    props: false,
    name: "auth-login-seller___en"
  }, {
    path: "/en/seller-panel",
    component: _4b48794e,
    props: false,
    name: "admin___en"
  }, {
    path: "/en/seller-register",
    component: _09d52178,
    props: false,
    name: "auth-register-seller___en"
  }, {
    path: "/en/surcharge",
    component: _327cb440,
    props: false,
    name: "surcharge___en"
  }, {
    path: "/mein-Konto/Anderungspasswort",
    component: _638d7cee,
    props: false,
    name: "profile-change-password___de"
  }, {
    path: "/mein-Konto/Auftrage",
    component: _2df44242,
    props: false,
    name: "profile-orders___de"
  }, {
    path: "/mein-Konto/Bewertungen",
    component: _40929a0e,
    props: false,
    name: "profile-comments___de"
  }, {
    path: "/mein-Konto/editieren",
    component: _5aaa4024,
    props: false,
    name: "profile-edit___de"
  }, {
    path: "/mein-Konto/Handelskredit",
    component: _7deaa25a,
    props: false,
    name: "profile-tradeCredit___de"
  }, {
    path: "/mein-Konto/Mitteilungen",
    component: _3ad6eacf,
    props: false,
    name: "messenger___de"
  }, {
    path: "/Telefon/Bestatigung",
    component: _00ea236e,
    props: false,
    name: "auth-confirm-phone___de"
  }, {
    path: "/Verkaufer-Panel/Add-Grosse",
    component: _19aae320,
    props: false,
    name: "admin-sizes-add___de"
  }, {
    path: "/Verkaufer-Panel/Add-Product-hinzufugen",
    component: _0a358d06,
    props: false,
    name: "admin-product-add___de"
  }, {
    path: "/Verkaufer-Panel/addon-groups",
    component: _75c94f33,
    props: false,
    name: "admin-addonGroups___de"
  }, {
    path: "/Verkaufer-Panel/Anderungs-Passwort",
    component: _41354094,
    props: false,
    name: "admin-change-password___de"
  }, {
    path: "/Verkaufer-Panel/Angebot-hinzufugen",
    component: _126772ea,
    props: false,
    name: "admin-offer-add___de"
  }, {
    path: "/Verkaufer-Panel/Angebote",
    component: _51f52bb6,
    props: false,
    name: "admin-offer-list___de"
  }, {
    path: "/Verkaufer-Panel/Beschreibungsvorlagen",
    component: _c7fe6f68,
    props: false,
    name: "admin-descriptionTemplates-list___de"
  }, {
    path: "/Verkaufer-Panel/Bestellungen",
    component: _05a4df48,
    props: false,
    name: "admin-orders___de"
  }, {
    path: "/Verkaufer-Panel/client-groups",
    component: _45c87514,
    props: false,
    name: "admin-clientGroups___de"
  }, {
    path: "/Verkaufer-Panel/editieren",
    component: _516eb3e2,
    props: false,
    name: "admin-profile-edit___de"
  }, {
    path: "/Verkaufer-Panel/Filter",
    component: _55f00ef7,
    props: false,
    name: "admin-filters___de"
  }, {
    path: "/Verkaufer-Panel/Grossen",
    component: _8f9ffea2,
    props: false,
    name: "admin-sizes-list___de"
  }, {
    path: "/Verkaufer-Panel/gutscheine-deals",
    component: _3c63e56e,
    props: false,
    name: "admin-coupons&groupDeal___de"
  }, {
    path: "/Verkaufer-Panel/Handelskredit",
    component: _ae4731b8,
    props: false,
    name: "admin-tradeCredit-list___de"
  }, {
    path: "/Verkaufer-Panel/Import-Export",
    component: _65203770,
    props: false,
    name: "admin-spreadsheet___de"
  }, {
    path: "/Verkaufer-Panel/Kunden",
    component: _69e22e0e,
    props: false,
    name: "admin-clients-list___de"
  }, {
    path: "/Verkaufer-Panel/marketing",
    component: _3696a0ca,
    props: false,
    name: "admin-marketing-list___de"
  }, {
    path: "/Verkaufer-Panel/Mitarbeiter",
    component: _9aa5b5c8,
    props: false,
    name: "admin-employees-list___de"
  }, {
    path: "/Verkaufer-Panel/Mitteilungen",
    component: _6220f10f,
    props: false,
    name: "admin-messenger___de"
  }, {
    path: "/Verkaufer-Panel/page",
    component: _3def0649,
    props: false,
    name: "admin-cms-page___de"
  }, {
    path: "/Verkaufer-Panel/post",
    component: _37d3cbda,
    props: false,
    name: "admin-cms-post___de"
  }, {
    path: "/Verkaufer-Panel/preise-nach-menge",
    component: _3d9892a8,
    props: false,
    name: "admin-pricesOnQuantity___de"
  }, {
    path: "/Verkaufer-Panel/Produkte",
    component: _037f141c,
    props: false,
    name: "admin-product-list___de"
  }, {
    path: "/Verkaufer-Panel/Store-Bearbeitung",
    component: _33df8ea8,
    props: false,
    name: "admin-store-edit___de"
  }, {
    path: "/Verkaufer-Panel/updatePage",
    component: _13e248b2,
    props: false,
    name: "admin-cms-updatePage___de"
  }, {
    path: "/Verkaufer-Panel/updatePost",
    component: _0dc70e43,
    props: false,
    name: "admin-cms-updatePost___de"
  }, {
    path: "/Verkaufer-Panel/Versand",
    component: _c358d03c,
    props: false,
    name: "admin-shipping___de"
  }, {
    path: "/Verkaufer-Panel/werkzeuge",
    component: _63aa6dd7,
    props: false,
    name: "admin-tools___de"
  }, {
    path: "/Verkaufer-Panel/Zahlung",
    component: _2e1f9f82,
    props: false,
    name: "admin-payment___de"
  }, {
    path: "/en/admin/bank-transfer",
    component: _b59a6050,
    props: false,
    name: "admin-bank-transfer___en"
  }, {
    path: "/en/admin/coupons",
    component: _1bee81ee,
    props: false,
    name: "admin-coupons___en"
  }, {
    path: "/en/admin/invoice",
    component: _568ec529,
    props: false,
    name: "admin-invoice___en"
  }, {
    path: "/en/my-account/change-password",
    component: _638d7cee,
    props: false,
    name: "profile-change-password___en"
  }, {
    path: "/en/my-account/comments",
    component: _40929a0e,
    props: false,
    name: "profile-comments___en"
  }, {
    path: "/en/my-account/edit",
    component: _5aaa4024,
    props: false,
    name: "profile-edit___en"
  }, {
    path: "/en/my-account/messages",
    component: _3ad6eacf,
    props: false,
    name: "messenger___en"
  }, {
    path: "/en/my-account/orders",
    component: _2df44242,
    props: false,
    name: "profile-orders___en"
  }, {
    path: "/en/my-account/trade-credit",
    component: _7deaa25a,
    props: false,
    name: "profile-tradeCredit___en"
  }, {
    path: "/en/phone/confirm",
    component: _00ea236e,
    props: false,
    name: "auth-confirm-phone___en"
  }, {
    path: "/en/seller-panel/add-offer",
    component: _126772ea,
    props: false,
    name: "admin-offer-add___en"
  }, {
    path: "/en/seller-panel/add-product",
    component: _0a358d06,
    props: false,
    name: "admin-product-add___en"
  }, {
    path: "/en/seller-panel/add-size",
    component: _19aae320,
    props: false,
    name: "admin-sizes-add___en"
  }, {
    path: "/en/seller-panel/addon-groups",
    component: _75c94f33,
    props: false,
    name: "admin-addonGroups___en"
  }, {
    path: "/en/seller-panel/change-password",
    component: _41354094,
    props: false,
    name: "admin-change-password___en"
  }, {
    path: "/en/seller-panel/client-groups",
    component: _45c87514,
    props: false,
    name: "admin-clientGroups___en"
  }, {
    path: "/en/seller-panel/clients",
    component: _69e22e0e,
    props: false,
    name: "admin-clients-list___en"
  }, {
    path: "/en/seller-panel/coupons-deals",
    component: _3c63e56e,
    props: false,
    name: "admin-coupons&groupDeal___en"
  }, {
    path: "/en/seller-panel/description-templates",
    component: _c7fe6f68,
    props: false,
    name: "admin-descriptionTemplates-list___en"
  }, {
    path: "/en/seller-panel/edit",
    component: _516eb3e2,
    props: false,
    name: "admin-profile-edit___en"
  }, {
    path: "/en/seller-panel/employees",
    component: _9aa5b5c8,
    props: false,
    name: "admin-employees-list___en"
  }, {
    path: "/en/seller-panel/filters",
    component: _55f00ef7,
    props: false,
    name: "admin-filters___en"
  }, {
    path: "/en/seller-panel/import-export",
    component: _65203770,
    props: false,
    name: "admin-spreadsheet___en"
  }, {
    path: "/en/seller-panel/marketing",
    component: _3696a0ca,
    props: false,
    name: "admin-marketing-list___en"
  }, {
    path: "/en/seller-panel/messages",
    component: _6220f10f,
    props: false,
    name: "admin-messenger___en"
  }, {
    path: "/en/seller-panel/offers",
    component: _51f52bb6,
    props: false,
    name: "admin-offer-list___en"
  }, {
    path: "/en/seller-panel/orders",
    component: _05a4df48,
    props: false,
    name: "admin-orders___en"
  }, {
    path: "/en/seller-panel/page",
    component: _3def0649,
    props: false,
    name: "admin-cms-page___en"
  }, {
    path: "/en/seller-panel/payment",
    component: _2e1f9f82,
    props: false,
    name: "admin-payment___en"
  }, {
    path: "/en/seller-panel/post",
    component: _37d3cbda,
    props: false,
    name: "admin-cms-post___en"
  }, {
    path: "/en/seller-panel/prices-on-quantity",
    component: _3d9892a8,
    props: false,
    name: "admin-pricesOnQuantity___en"
  }, {
    path: "/en/seller-panel/products",
    component: _037f141c,
    props: false,
    name: "admin-product-list___en"
  }, {
    path: "/en/seller-panel/shipping",
    component: _c358d03c,
    props: false,
    name: "admin-shipping___en"
  }, {
    path: "/en/seller-panel/sizes",
    component: _8f9ffea2,
    props: false,
    name: "admin-sizes-list___en"
  }, {
    path: "/en/seller-panel/store-edition",
    component: _33df8ea8,
    props: false,
    name: "admin-store-edit___en"
  }, {
    path: "/en/seller-panel/tools",
    component: _63aa6dd7,
    props: false,
    name: "admin-tools___en"
  }, {
    path: "/en/seller-panel/trade-credit",
    component: _ae4731b8,
    props: false,
    name: "admin-tradeCredit-list___en"
  }, {
    path: "/en/seller-panel/updatePage",
    component: _13e248b2,
    props: false,
    name: "admin-cms-updatePage___en"
  }, {
    path: "/en/seller-panel/updatePost",
    component: _0dc70e43,
    props: false,
    name: "admin-cms-updatePost___en"
  }, {
    path: "/Verkaufer-Panel/addon-groups/creatieren",
    component: _a3b8fc58,
    props: false,
    name: "admin-addonGroups-manage___de"
  }, {
    path: "/Verkaufer-Panel/Beschreibungsvorlagen/hinzufugen",
    component: _210433e3,
    props: false,
    name: "admin-descriptionTemplates-add___de"
  }, {
    path: "/Verkaufer-Panel/client-groups/manage",
    component: _311fd3f1,
    props: false,
    name: "admin-clientGroups-manage___de"
  }, {
    path: "/Verkaufer-Panel/groupDeal/hinzufugen",
    component: _007491ce,
    props: false,
    name: "admin-groupDeal-add___de"
  }, {
    path: "/Verkaufer-Panel/marketing/hinzufugen",
    component: _2b9ea6b4,
    props: false,
    name: "admin-marketing-add___de"
  }, {
    path: "/Verkaufer-Panel/Mitarbeiter/Hinzufugen",
    component: _301e05da,
    props: false,
    name: "admin-employees-add___de"
  }, {
    path: "/en/seller-panel/addon-groups/manage",
    component: _a3b8fc58,
    props: false,
    name: "admin-addonGroups-manage___en"
  }, {
    path: "/en/seller-panel/client-groups/manage",
    component: _311fd3f1,
    props: false,
    name: "admin-clientGroups-manage___en"
  }, {
    path: "/en/seller-panel/description-templates/add",
    component: _210433e3,
    props: false,
    name: "admin-descriptionTemplates-add___en"
  }, {
    path: "/en/seller-panel/employees/add",
    component: _301e05da,
    props: false,
    name: "admin-employees-add___en"
  }, {
    path: "/en/seller-panel/groupDeal/add",
    component: _007491ce,
    props: false,
    name: "admin-groupDeal-add___en"
  }, {
    path: "/en/seller-panel/marketing/add",
    component: _2b9ea6b4,
    props: false,
    name: "admin-marketing-add___en"
  }, {
    path: "/",
    component: _0381530e,
    props: false,
    name: "index___de"
  }, {
    path: "/en/seller-panel/description-templates/edit/:id",
    component: _a96e2090,
    props: false,
    name: "admin-descriptionTemplates-edit___en"
  }, {
    path: "/en/seller-panel/marketing/edit/:id",
    component: _180651f2,
    props: false,
    name: "admin-marketing-edit___en"
  }, {
    path: "/en/email/confirm/:token",
    component: _86bb2ac8,
    props: false,
    name: "auth-confirm-email___en"
  }, {
    path: "/en/my-account/order/:id",
    component: _6ad1ed2a,
    props: true,
    name: "profile-orders-id___en"
  }, {
    path: "/en/seller-panel/client/:id",
    component: _bbb05792,
    props: false,
    name: "admin-clients-details___en"
  }, {
    path: "/en/seller-panel/deals/:groupDeal",
    component: _14b254ff,
    props: false,
    name: "admin-groupDeal-deals___en"
  }, {
    path: "/en/seller-panel/employees/:id",
    component: _7c1566f0,
    props: false,
    name: "admin-employees-edit___en"
  }, {
    path: "/en/seller-panel/groupDeal/:id",
    component: _b690597c,
    props: false,
    name: "admin-groupDeal-edit___en"
  }, {
    path: "/en/seller-panel/offer/:id",
    component: _3364dcde,
    props: false,
    name: "admin-offer-edit___en"
  }, {
    path: "/en/seller-panel/order/:id",
    component: _745bf9c4,
    props: true,
    name: "admin-orders-id___en"
  }, {
    path: "/en/seller-panel/product/:id",
    component: _0d889d5e,
    props: false,
    name: "admin-product-edit___en"
  }, {
    path: "/en/seller-panel/size/:id",
    component: _710fafca,
    props: false,
    name: "admin-sizes-edit___en"
  }, {
    path: "/en/seller-panel/trade-credit/:id",
    component: _0e95222c,
    props: false,
    name: "admin-tradeCredit-details___en"
  }, {
    path: "/Verkaufer-Panel/Beschreibungsvorlagen/bearbeiten/:id",
    component: _a96e2090,
    props: false,
    name: "admin-descriptionTemplates-edit___de"
  }, {
    path: "/Verkaufer-Panel/marketing/bearbeiten/:id",
    component: _180651f2,
    props: false,
    name: "admin-marketing-edit___de"
  }, {
    path: "/E-Mail/Bestatigung/:token",
    component: _86bb2ac8,
    props: false,
    name: "auth-confirm-email___de"
  }, {
    path: "/en/bestsellers/:categoryName-c:categoryId(\\d+)",
    component: _8c4e307c,
    props: false,
    name: "products-bestsellers-category___en"
  }, {
    path: "/en/groupDeal/:identify",
    component: _7fd54347,
    props: false,
    name: "groupDeal___en"
  }, {
    path: "/en/novelties/:categoryName-c:categoryId(\\d+)",
    component: _92ec779a,
    props: false,
    name: "products-novelties-category___en"
  }, {
    path: "/en/reset-password/:token",
    component: _11c5ecdc,
    props: false,
    name: "auth-reset-password___en"
  }, {
    path: "/en/sale/:categoryName-c:categoryId(\\d+)",
    component: _2d89ff66,
    props: false,
    name: "products-sale-category___en"
  }, {
    path: "/mein-Konto/Bestellung/:id",
    component: _6ad1ed2a,
    props: true,
    name: "profile-orders-id___de"
  }, {
    path: "/Verkaufer-Panel/Angebot/:id",
    component: _3364dcde,
    props: false,
    name: "admin-offer-edit___de"
  }, {
    path: "/Verkaufer-Panel/Bestellung/:id",
    component: _745bf9c4,
    props: true,
    name: "admin-orders-id___de"
  }, {
    path: "/Verkaufer-Panel/deals/:groupDeal",
    component: _14b254ff,
    props: false,
    name: "admin-groupDeal-deals___de"
  }, {
    path: "/Verkaufer-Panel/Grosse/:id",
    component: _710fafca,
    props: false,
    name: "admin-sizes-edit___de"
  }, {
    path: "/Verkaufer-Panel/groupDeal/:id",
    component: _b690597c,
    props: false,
    name: "admin-groupDeal-edit___de"
  }, {
    path: "/Verkaufer-Panel/Handelskredit/:id",
    component: _0e95222c,
    props: false,
    name: "admin-tradeCredit-details___de"
  }, {
    path: "/Verkaufer-Panel/Kunde/:id",
    component: _bbb05792,
    props: false,
    name: "admin-clients-details___de"
  }, {
    path: "/Verkaufer-Panel/Mitarbeiter/:id",
    component: _7c1566f0,
    props: false,
    name: "admin-employees-edit___de"
  }, {
    path: "/Verkaufer-Panel/Produkte/:id",
    component: _0d889d5e,
    props: false,
    name: "admin-product-edit___de"
  }, {
    path: "/bestseller/:categoryName-c:categoryId(\\d+)",
    component: _8c4e307c,
    props: false,
    name: "products-bestsellers-category___de"
  }, {
    path: "/en/:categoryName-c:categoryId(\\d+)",
    component: _6c33de05,
    props: false,
    name: "products-category___en"
  }, {
    path: "/en/:slug",
    component: _e63e1f6e,
    props: false,
    name: "cms-postPage___en"
  }, {
    path: "/groupDeal/:identify",
    component: _7fd54347,
    props: false,
    name: "groupDeal___de"
  }, {
    path: "/novelties/:categoryName-c:categoryId(\\d+)",
    component: _92ec779a,
    props: false,
    name: "products-novelties-category___de"
  }, {
    path: "/Passwort-zurucksetzen/:token",
    component: _11c5ecdc,
    props: false,
    name: "auth-reset-password___de"
  }, {
    path: "/sale/:categoryName-c:categoryId(\\d+)",
    component: _2d89ff66,
    props: false,
    name: "products-sale-category___de"
  }, {
    path: "/en/*",
    component: _44f4b066,
    props: false,
    name: "*___en"
  }, {
    path: "/:categoryName-c:categoryId(\\d+)",
    component: _6c33de05,
    props: false,
    name: "products-category___de"
  }, {
    path: "/:slug",
    component: _e63e1f6e,
    props: false,
    name: "cms-postPage___de"
  }, {
    path: "/*",
    component: _44f4b066,
    props: false,
    name: "*___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
